import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Loading } from "../components/loading"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { useForm } from "react-hook-form"
import Footer from "../components/Footer"
import BlockContent from "@sanity/block-content-to-react"
import LogRocket from "logrocket"
LogRocket.init("ziiqiw/studio-conscious")

// import { CartItems } from "../components/cart"
// import { FiShoppingCart } from "react-icons/fi"

const Header = styled.h1`
  ${tw`uppercase text-center mt-20 text-3xl md:text-6xl`}
`

const TitleSpan = styled.span`
  color: ${({ color }) => color};
`

const SubTitle = styled.h2`
  ${tw`text-center italic`}
  color: ${({ color }) => color};
`

const QuoteWrapperMobile = styled.div`
  display: block;
  @media (min-width: 1600px) {
    display: none;
  }
`

const QuoteWrapper = styled.div`
  display: none;
  @media (min-width: 1600px) {
    display: block;
  }
`

const Quote = styled.q`
  ${tw`text-center block leading-relaxed text-sm sm:text-xl md:text-2xl lg:text-3xl ml-auto mr-auto font-semibold`}
  font-family: "raleway";
  font-weight: 300;
  padding:25px 50px;
    width:90%;
  @media(min-width:500px){
    padding:0 50px;
  }
  color:#000;
  //color: ${({ color }) => color};
  padding-bottom:0;
  @media(min-width:1600px){
    width: 650px;
    color:#f2f1ef;
    margin-top: 250px;
    padding-bottom:40px;
  }
  quotes: "“" "”" "‘" "’";
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`

const ImgWrap = styled.div`
  background-image: url(${({ background }) => background});
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: 175px;
  @media (min-width: 337px) {
    height: 200px;
  }
  @media (min-width: 400px) {
    height: 250px;
  }
  @media (min-width: 640px) {
    height: 400px;
  }
  @media (min-width: 800px) {
    height: 600px;
  }
  @media (min-width: 1152px) {
    height: 650px;
  }
  @media (min-width: 1280px) {
    height: 800px;
  }
  @media (min-width: 1400px) {
    height: 1000px;
  }
  overflow: hidden;
  .logo {
    margin: 50px auto;
    display: block !important;
  }
`

const Banner = styled.div`
  //background: ${({ background }) => background};
`

const ShopWrapper = styled.div`
  //background: #d8d4c9;
  padding-bottom: 100px;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
`
const MissionWrap = styled.div`
  background: #d8d4c9;
  padding-bottom: 50px;
  position: relative;
`

const AboutWrap = styled.div`
  margin-top: 200px;
  @media (max-width: 600px) {
    margin-top: 50px;
  }
`

const EightyWrap = styled.div`
  width: 500px;
  @media (max-width: 600px) {
    width: 200px;
    bottom: 0;
    margin-left: -100px;
  }
  margin: 50px auto 0 auto;
  //transform: translateY(150px);
  position: absolute;
  bottom: -150px;
  margin-left: -250px;
  left: 50%;
`

const Title = styled.h4`
  font-family: "Playfair Display";
  font-weight: 500;
  color: ${({ background }) => background};
  ${tw`text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl`}
  margin-top:0;
  padding-bottom: 0;
  @media (min-width: 600px) {
    margin-bottom: 50px;
  }
  &.moveDown {
    @media (min-width: 600px) {
      transform: translateY(50px);
    }
  }
  &.marginTop {
    @media (max-width: 600px) {
      padding-top: 50px;
      margin-bottom: 10px;
    }
  }
`
const TitleSmall = styled.h5`
  color: ${({ color }) => color};
  ${tw`lg:text-6xl m-0`}
  font-family: "Playfair Display";
`

const ImageWrapper = styled.div``

const ImageWrap = styled.div`
  ${tw`mr-auto ml-auto text-center`}
  height: 100px;
  transform: translateY(-150%);
  width: 200px;
  h3 {
    font-family: "Playfair Display";
    font-size: 4rem;
    text-transform: lowercase;
    font-weight: 500;
    ${tw`text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl`}
  }
  @media (min-width: 768px) {
    width: 300px;
    height: 131px;
    transform: translateY(-175%);
  }
  @media (min-width: 1280px) {
    width: 400px;
    transform: translateY(-200%);
  }
  @media (min-width: 1440px) {
    transform: translateY(-150%);
    height: 200px;
    width: 500px;
  }
  @media (min-width: 1920px) {
    transform: translateY(-100%);
    height: 250px;
  }
`

const ContactBlock = styled.div`
  ${tw`relative`}
  margin-top:200px;
  padding-bottom: 200px;
  @media (min-width: 600px) {
    margin-top: 450px;
  }
  background: #d8d4c9;
`

const Grid = styled.div`
  ${tw`w-3/4 mr-auto ml-auto grid lg:grid-cols-3 col-gap-10`}
  div {
    display: block;
  }
  &.moveUp {
    transform: translateY(-50px);
    background: #d8d4c9;
  }
  p {
    ${tw`leading-loose`}
  }
`
const GridFull = styled.div`
  ${tw`w-full ml-auto mr-auto`}
  //height:3500px;
  position: relative;
  z-index: 10;
  div.gridgrid {
    ${tw`mr-auto ml-auto block col-gap-20`}
    width:75%;
    @media (max-width: 600px) {
      width: 100%;
    }
    .about {
      display: block;
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }
  div {
    display: block;
  }
  &.moveUp {
    transform: translateY(-50px);
    background: #d8d4c9;
    //background-image: url(/test-1.jpg);
    background-size: 175%;
    padding: 25px 0;
  }
  p.about {
    ${tw`leading-loose`}
    padding:100px;
    @media (min-width: 1280px) {
      padding: 100px;
    }
    @media (min-width: 1400px) {
      padding: 150px;
    }
  }
  .halfGrid {
    p {
      @media (max-width: 600px) {
        line-height: 1.625;
      }
    }
    &:nth-child(even) {
      div {
        grid-column-start: 2;
      }
      .about {
        grid-row-start: 1;
      }
    }
    display: grid;
    @media (max-width: 1400px) {
      display: block;
    }
    div {
      background: #d8d4c9;
      width: 350px;
      @media (max-width: 1400px) {
        width: initial;
      }
      justify-self: center;
    }
    grid-template-columns: 1fr 1fr;
    &:first-child {
      @media (min-width: 600px) {
        margin-top: 75px;
      }
    }
  }
  svg {
    position: absolute;
    margin-left: -67px;
    top: -50px;
    left: 50%;
    @media (max-width: 1400px) {
      display: none;
    }
  }
  z-index: 1;
`

const ColorBlock = styled.article`
  //padding: 0px 50px;
  display: grid;
  @media (max-width: 625px) {
    display: block;
  }
  justify-items: center;
  position: relative;
  margin-bottom: 50px;
  width: 325px;
  @media (max-width: 320px) {
    width: 100%;
  }
  &:nth-child(even) {
    transform: translateX(150px);
    @media (max-width: 1400px) {
      transform: translateX(0px);
      margin: 0 auto 100px auto;
    }
    section {
      background-color: ${({ color }) => color};
      width: 275px;
      height: 235px;
      top: 80px;
      right: -40px;
      z-index: -10;
      @media (max-width: 625px) {
        top: 0;
        right: 0;
        height: 40px;
      }
    }
  }
  &:nth-child(odd) {
    transform: translateX(-150px);
    @media (max-width: 1400px) {
      transform: translateX(0px);
      margin: 0 auto 100px auto;
    }
    section {
      background-color: ${({ color }) => color};
      width: 275px;
      height: 235px;
      top: 80px;
      right: 90px;
      z-index: -10;
      @media (max-width: 625px) {
        top: 0;
        right: 0;
        height: 40px;
      }
    }
  }
`

const ColorLay = styled.section`
  position: absolute;
  display: grid;
  align-items: end;
  @media (max-width: 625px) {
    position: relative;
  }
  p {
    padding-left: 10px;
    color: #fff;
  }
`

const ProductWrap = styled.div`
  background: #d8d4c9;
  // @media (max-width: 600px) {
  //   padding-bottom: 15px;
  // }
`

const Span = styled.span`
  color: ${({ color }) => color};
`

const SpanPercent = styled.span`
  color: ${({ color }) => color};
  ${tw`text-6xl font-bold pl-2 pr-2`}
`

const ProductHeader = styled.h3`
  ${tw`text-center`}

  padding: 50px;
  @media (max-width: 600px) {
    padding: 20px;
  }
  span {
    ${tw`pl-1 pr-1`}
    text-transform:lowercase;
    display: block;
   // &:first-child {
      font-family: "Playfair Display";
      font-weight: 400;
      ${tw`text-2xl`}
    //}
    // &:last-child {
    //   font-family: raleway;
    //   font-weight: 300;
    //   ${tw`text-2xl`}
    // }
  }
  .aboutHide {
    display: none;
    font-size: 1.1rem;

    @media (max-width: 1400px) {
      display: block;
    }
  }
`
const ProductHeaderButtons = styled.h3`
  ${tw`text-center`}
  background:#e8e6e2;
  //border: 1px solid #78807a;
  border-radius: 5px;
  width: 300px;
  @media (max-width: 500px) {
    width: 100%;
  }
  color: #78807a;
  margin: 25px auto;

  padding: 0px;
  &:hover {
    background: #78807a;
    span {
      color: #fff;
    }
  }
  span {
    ${tw`pl-1 pr-1`}
    text-transform:lowercase;
    //display: block;
    color: #78807a;
    &:first-child {
      font-family: "Playfair Display";
      font-weight: 400;
      ${tw`text-2xl`}
    }
    &:last-child {
      font-family: "Playfair Display";
      font-weight: 400;
      ${tw`text-2xl`}
    }
  }
  .aboutHide {
    display: none;
    @media (max-width: 1400px) {
      display: block;
    }
  }
`

const CenterBlock = styled.div`
  ${tw`text-left block leading-relaxed lg:text-xl lg:w-3/4 ml-auto mr-auto font-semibold lg:p-10`}
  font-family: "raleway";
  font-weight: 300;
  padding: 0 40px;
  .italics {
    text-align: center;
    font-weight: 400;
    margin-top: 0;
  }
  .marginRemover {
    margin-bottom: 0;
  }
  figure {
    text-align: center;
    img {
      @media (max-width: 600px) {
        width: 90%;
      }
    }
  }
`

const Creators = styled.div`
  ${tw`grid xl:grid-cols-2 lg:w-3/4 mr-auto ml-auto p-5`}
  &.direction-l {
    direction: rtl;
    text-align: left;
  }
  div {
    ${tw`p-10`}
  }
`

const Form = styled.div`
  ${tw`md:w-1/2 mr-auto ml-auto`}

  input,
  textarea {
    ${tw`mt-3 mb-3 p-2 w-10/12 block ml-auto mr-auto md:w-full`}
    border:none;
    background: none;
    border-bottom: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
    line-height: 22px;
    height: 100px;
  }
  input[type="submit"] {
    //border: 1px solid #000;
    border-radius: 20px;
    background: #fff;
    color: grey;
    width: 300px;
    &:hover {
      cursor: pointer;
    }
    ${tw`ml-auto mr-auto md:ml-0 block mt-5 mb-5`}
    &.disabled {
      background: #78807a;
      color: #fff;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`

const FormBlock = styled.form`
  ${tw`grid lg:grid-cols-2 col-gap-32`}
  align-items: center;
  justify-items: center;
  div {
    &:first-child {
      width: 100%;
    }
  }
`

// const CartButton = styled.div`
//   position: fixed;
//   top: 50px;
//   right: 15px;
//   @media (min-width: 1024px) {
//     top: 15px;
//     right: 30px;
//   }
//   z-index: 6000;
//   button {
//     &:hover {
//       cursor: pointer;
//     }
//     background: none;
//     border: none;
//     &:focus {
//       outline: none;
//     }
//   }
// `

const AboutUs = styled.div`
  width: 1000px;
  margin: 100px auto 0 auto;
  display: grid;
  //background: red;
  @media (max-width: 600px) {
    margin: 25px auto 0 auto;
  }
  @media (max-width: 1100px) {
    display: block;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
  grid-column-gap: 100px;
  grid-template-columns: 450px 1fr;
  //align-items: center;
  //justify-items: center;
  .image {
    width: 400px;
    margin: 0 auto;
    @media (max-width: 525px) {
      width: 90%;
    }
  }
  .about {
    padding-top: 100px;
    line-height: 2;
    @media (max-width: 1100px) {
      width: 66%;
      margin: 0 auto;
    }
    @media (max-width: 600px) {
      width: 90%;
      margin: 0 auto;
      padding-top: 0;
    }
  }
  .imageBlock {
    position: relative;
    p {
      padding: 20px;
      color: #fff;
      position: absolute;
      &.p0 {
        top: 50px;
        background: #ae9894;
        left: -25px;
      }
      &.p1 {
        bottom: 50px;
        right: 0;
        background: #5f5744;
        right: -25px;
      }
    }
  }
`

const LineDrawing = styled.div`
  margin: -225px auto 105px auto;
  width: 300px;
  transform: rotatez(-30deg);
`
const Success = styled.div`
  text-align: center;
  svg {
    width: 50px;
    margin: 0 auto;
  }
`

export const CouponBanner = styled.div`
  margin-top: 100px;
  h2,
  h3 {
    color: #8f9687;
    text-align: center;
    font-family: "Playfair Display";
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 18px;
  }
`

const IndexPage = ({ data, location }) => {
  const [showNum, SetShowNum] = useState(0)
  const [showNumTrue, SetShowNumTrue] = useState(0)
  const [showNumFurniture, SetshowNumFurniture] = useState(0)

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  useEffect(() => {
    var i = 0

    var interval = setInterval(increment1, 5000)

    let stop = data.productsCurations.edges.length // right now equates to 4

    // let next
    // let nextI

    // data.productsCurations.edges.map(({ node: product }, i) => {
    //   if (product.archived === true) {
    //     next = i
    //     nextI = i + 1
    //   }
    // })

    function increment1() {
      i = i + 1

      if (i === stop) {
        i = 0
      }
      // if (i === next) {
      //   console.log(i + " :increment")
      //   console.log(next + " :next")
      //   console.log(nextI + " :nextI")
      //   if (nextI === stop) {
      //     i = 0
      //   } else {
      //     i = nextI
      //   }
      // }

      SetShowNum(i)
    }
  }, [])

  useEffect(() => {
    var i = 0

    var interval = setInterval(increment, 5000)

    var stop = data.productsGoods.edges.length // right now equates to 4

    function increment() {
      i = i + 1

      if (i === stop) {
        i = 0
      }

      SetShowNumTrue(i)
    }
  }, [])

  useEffect(() => {
    var i = 0

    var interval = setInterval(increment, 5000)

    var stop = data.productsFurniture.edges.length // right now equates to 4

    function increment() {
      i = i + 1

      if (i === stop) {
        i = 0
      }

      SetshowNumFurniture(i)
    }
  }, [])

  const { register, handleSubmit, watch, errors } = useForm()

  let fetchUrl
  if (location.origin === "http://localhost:8000") {
    fetchUrl = `http://localhost:8888/.netlify/functions/product-question`
  } else if (location.origin === "http://dev.studioconscious.com") {
    fetchUrl = `https://studioconscious-dev.netlify.app/.netlify/functions/product-question`
  } else {
    fetchUrl = "/.netlify/functions/product-question"
  }

  const [captchaValue, setCaptchaValue] = useState(null)
  const [submitButton, setSubmitButton] = useState(true)
  const [captchaFailed, setCaptchaFailed] = useState(false)
  const [nextStage, setNextStage] = useState(false)
  const [failedEmail, setFailedEmail] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    console.log(data)
    console.log(fetchUrl)
    const response = await fetch(fetchUrl, {
      method: "POST",
      body: JSON.stringify({
        data,
        captcha: captchaValue,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.captcha === false) {
          setCaptchaFailed(true)
        }
        if (data.email === true) {
          setNextStage(true)
        } else {
          setNextStage(true)
          setFailedEmail(true)
        }
      })
      .catch(error => {
        console.error("Error:", error)
        setNextStage(true)
        setFailedEmail(true)
      })
  }

  function retry() {
    setLoading(false)
  }

  function onVerifyCaptcha(token) {
    console.log(token)
    setCaptchaValue(token)
    setSubmitButton(false)
  }

  // const [close, setClose] = useState(false)

  // const toggleCart = () => {
  //   setClose(!close)
  // }
  // const showCart = () => {
  //   setClose(true)
  // }

  console.log("here")

  console.log(data.productsFurniture)
  console.log("here")

  return (
    <>
      <Layout location={location}>
        <SEO image={data.sanityHomePage.heroImage.asset.url} title="Home" />
        {/* <CartItems close={close} location={location} />
        <CartButton>
          <button onClick={toggleCart}>
            <FiShoppingCart size={25} />
          </button>
        </CartButton> */}

        {/* <SubTitle color={data.sanityColors.secondaryColor.hex}>
          {data.sanityHomePage.subHeader}
        </SubTitle> */}
      </Layout>
      <ImgWrap background={data.sanityHomePage.heroImage.asset.url}>
        {/* <Img
          alt={data.sanityHomePage.logoImageWhite.alt}
          className="logo"
          fixed={
            data.sanityHomePage.logoImageWhite &&
            data.sanityHomePage.logoImageWhite.asset.fixed
          }
        /> */}
        <QuoteWrapper>
          <Quote color={data.sanityColors.primaryColor.hex}>
            {data.sanityHomePage.quote}
          </Quote>
        </QuoteWrapper>
      </ImgWrap>

      <QuoteWrapperMobile>
        <Quote color={data.sanityColors.primaryColor.hex}>
          {data.sanityHomePage.quote}
        </Quote>
      </QuoteWrapperMobile>

      <ShopWrapper>
        {/* <CouponBanner>
          <h2>25% off all products </h2>
          <h3>automatically applied at checkout</h3>
        </CouponBanner> */}
        <Banner background={data.sanityColors.primaryColor.hex}>
          <Title color={data.sanityColors.secondaryColor.hex}>
            {data.sanityHomePage.shopTitle}
          </Title>
          {/* <LineDrawing>
          <Img
            fixed={
              data.sanityHomePage.lineDrawing1 &&
              data.sanityHomePage.lineDrawing1.asset.fixed
            }
          />
        </LineDrawing> */}
        </Banner>
        <Grid>
          <>
            {data.productsCurations.edges.map(({ node: product }, i) => {
              return (
                <React.Fragment key={i + 1000}>
                  <>
                    {showNum === i && (
                      <Link to="/curations/">
                        <ImageWrapper className={i}>
                          <Img
                            fluid={
                              product.featureImage &&
                              product.featureImage.asset.fluid
                            }
                          />
                        </ImageWrapper>
                        <ProductHeaderButtons>
                          {data.sanityCurations.header.map((head, i) => {
                            return (
                              <Span key={i} color={head.myColor.hex}>
                                {head.title}
                              </Span>
                            )
                          })}
                        </ProductHeaderButtons>
                      </Link>
                    )}
                  </>
                </React.Fragment>
              )
            })}
          </>
          <>
            {data.productsGoods.edges.map(({ node: product }, i) => {
              return (
                <React.Fragment key={i}>
                  <>
                    {/* {product.archived === false ? ( */}
                    <>
                      {showNumTrue === i && (
                        <Link to="/goods/">
                          <ImageWrapper className={i}>
                            <Img
                              fluid={
                                product.featureImage &&
                                product.featureImage.asset.fluid
                              }
                            />
                          </ImageWrapper>
                          <ProductHeaderButtons>
                            {data.sanityGoods.header.map((head, i) => {
                              return (
                                <Span key={i} color={head.myColor.hex}>
                                  {head.title}
                                </Span>
                              )
                            })}
                          </ProductHeaderButtons>
                        </Link>
                      )}
                    </>
                    {/* ) : ( */}
                    {/* <div>
                        <div>all goodsarchived</div>
                        <ProductHeader>
                          {data.sanityGoods.header.map((head, i) => {
                            return (
                              <Span key={i} color={head.myColor.hex}>
                                {head.title}
                              </Span>
                            )
                          })}
                        </ProductHeader>
                      </div> */}
                    {/* )} */}
                  </>
                </React.Fragment>
              )
            })}
          </>
          <>
            {JSON.stringify(data.productsFurniture.edges) === "[]" ? (
              <Link to="/furniture/">
                <ImageWrapper>
                  <Img fluid={data.file.childImageSharp.fluid} />
                </ImageWrapper>
                <ProductHeaderButtons>
                  <Span>Conscious Furniture</Span>
                </ProductHeaderButtons>
              </Link>
            ) : null}
          </>
          <>
            {data.productsFurniture.edges.map(({ node: product }, i) => {
              return (
                <React.Fragment key={i}>
                  <>
                    {/* {product.archived === false ? ( */}
                    <>
                      {showNumFurniture === i && (
                        <Link to="/furniture/">
                          <ImageWrapper className={i}>
                            <Img
                              fluid={
                                product.featureImage &&
                                product.featureImage.asset.fluid
                              }
                            />
                          </ImageWrapper>
                          <ProductHeaderButtons>
                            {data.sanityFurniture.header.map((head, i) => {
                              return (
                                <Span key={i} color={head.myColor.hex}>
                                  {head.title}
                                </Span>
                              )
                            })}
                          </ProductHeaderButtons>
                        </Link>
                      )}
                    </>
                    {/* ) : ( */}
                    {/* <div>
                        <div>all furniture archived</div>
                        <ProductHeader>
                          {data.sanityGoods.header.map((head, i) => {
                            return (
                              <Span key={i} color={head.myColor.hex}>
                                {head.title}
                              </Span>
                            )
                          })}
                        </ProductHeader>
                      </div> */}
                    {/* )} */}
                  </>
                </React.Fragment>
              )
            })}
          </>
          {/* <>
            {data.allSanityConcepts.edges.map(({ node: concept }, i) => {
              return (
                <React.Fragment key={i}>
                  {showNumConcept === i && (
                    <Link to="/concepts">
                      <ImageWrapper className={i}>
                        <Img
                          fluid={
                            concept.featureImage &&
                            concept.featureImage.asset.fluid
                          }
                        />
                      </ImageWrapper>
                      <ProductHeaderButtons>
                        {data.sanityConceptsPage.header.map((head, i) => {
                          return (
                            <Span key={i} color={head.myColor.hex}>
                              {head.title}
                            </Span>
                          )
                        })}
                      </ProductHeaderButtons>
                    </Link>
                  )}
                </React.Fragment>
              )
            })}
          </> */}
        </Grid>
      </ShopWrapper>
      <Banner background={data.sanityColors.primaryColor.hex}>
        <Title className="" color={data.sanityColors.secondaryColor.hex}>
          {data.sanityHomePage.AboutTitle}
        </Title>
      </Banner>
      <GridFull className="">
        {/* <svg
          className="bgSvg"
          width="134"
          height="3516"
          viewBox="0 0 134 3516"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="67"
            x2="67"
            y2="3516"
            stroke="#C4C4C4"
            strokeOpacity="0.63"
            strokeWidth="2"
          />
          <line y1="138" x2="66" y2="138" stroke="#D9D9D9" strokeWidth="2" />
          <line
            x1="68"
            y1="1571"
            x2="134"
            y2="1571"
            stroke="#D9D9D9"
            strokeWidth="2"
          />
          <line y1="2608" x2="66" y2="2608" stroke="#D9D9D9" strokeWidth="2" />
        </svg> */}
        <div className="gridgrid">
          {data.sanityHomePage.aboutTitle.map((about, i) => {
            return (
              <div className="halfGrid" key={i}>
                <ProductWrap>
                  <ProductHeader>
                    {about.title.map((head, i) => {
                      return (
                        <>
                          <Span key={i} color={head.myColor.hex}>
                            {head.title}
                          </Span>
                        </>
                      )
                    })}
                    <p className="aboutHide">{about.text}</p>
                  </ProductHeader>
                  {about.blockItem.map((item, i) => {
                    return (
                      <ColorBlock color={item.color.hex}>
                        <ColorLay>
                          <p>{item.title}</p>
                        </ColorLay>
                        <Link to={item.urlPath}>
                          <Img fixed={item.image && item.image.asset.fixed} />
                        </Link>
                      </ColorBlock>
                    )
                  })}
                  <div></div>
                </ProductWrap>
                <p className="about">{about.text}</p>
              </div>
            )
          })}
        </div>
      </GridFull>
      <MissionWrap>
        <Banner background={data.sanityColors.primaryColor.hex}>
          <Title
            className="moveDown marginTop"
            color={data.sanityColors.secondaryColor.hex}
          >
            {data.sanityHomePage.missionBanner}
          </Title>
        </Banner>
        <CenterBlock>
          {/* <p className="italics">{data.sanityHomePage.missionTitle}</p> */}
          {/* <p className="marginRemover">{data.sanityHomePage.missionDesc}</p> */}
          <BlockContent
            imageOptions={{ w: 320, h: 240, fit: "max" }}
            projectId="9pb8ktqh"
            dataset="production"
            blocks={data.sanityHomePage._rawMissionDesc}
            serializers={serializers}
          />
          {/* <EightyWrap>
            <Img
              fluid={
                data.sanityHomePage.percent &&
                data.sanityHomePage.percent.asset.fluid
              }
            />
          </EightyWrap> */}
          {/* <SpanPercent color={data.sanityColors.primaryColor.hex}>
          {data.sanityHomePage.percentLeft}
        </SpanPercent>
        <SpanPercent color={data.sanityColors.secondaryColor.hex}>
          {data.sanityHomePage.percentRight}
        </SpanPercent> */}
        </CenterBlock>
      </MissionWrap>
      <AboutWrap>
        <Banner background={data.sanityColors.primaryColor.hex}>
          <Title color={data.sanityColors.secondaryColor.hex}>
            {data.sanityHomePage.creatorsBanner}
          </Title>
        </Banner>
        {/* <div id="about">
        {data.sanityHomePage.creatorsBlock.map((block, i) => {
          return (
            <Creators
              key={i + 99}
              className={"direction-" + (i % 2 ? "r" : "l")}
            >
              <div>
                <TitleSmall color={data.sanityColors.tertiaryColor.hex}>
                  {block.Title}
                </TitleSmall>
                <p>{block.text}</p>
              </div>
              <Img
                fluid={block.creatorImage && block.creatorImage.asset.fluid}
              />
            </Creators>
          )
        })}
      </div> */}
        <AboutUs id="about">
          <div className="image">
            <div className="imageBlock">
              <Img
                fluid={
                  data.sanityHomePage.creatorsBlock.creatorImage.asset.fluid
                }
              />
              {data.sanityHomePage.creatorsBlock.Title.map((t, i) => {
                return (
                  <p key={i} className={`p${i}`}>
                    {t}
                  </p>
                )
              })}
            </div>
          </div>
          <div className="about">
            <p>{data.sanityHomePage.creatorsBlock.text}</p>
          </div>
        </AboutUs>
      </AboutWrap>
      <ContactBlock>
        <ImageWrap>
          <h3>Contact Us</h3>
          <h2>
            <a href="mailto:hello@studioconscious.com">
              hello@studioconscious.com
            </a>
          </h2>
          <Img
            fluid={
              data.sanityHomePage.contactImage &&
              data.sanityHomePage.contactImage.asset.fluid
            }
          />
        </ImageWrap>
        <Loading>
          <Form id="contact">
            {!loading ? (
              <>
                <FormBlock onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    {/* include validation with required or other standard HTML validation rules */}
                    <input
                      name="name"
                      placeholder="Full Name"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors.name && <span>This field is required</span>}
                    <input
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      ref={register({ required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors.email && <span>This field is required</span>}
                    <textarea
                      name="question"
                      placeholder="What is your question?"
                      ref={register({ required: true })}
                    />
                  </div>
                  {/* include validation with required or other standard HTML validation rules */}
                  <div>
                    {/* <HCaptcha
                      className="captcha"
                      sitekey="acaf5962-6fc2-4e49-86db-3b13f0a60f24"
                      onVerify={onVerifyCaptcha}
                      theme="dark"
                    /> */}
                    <input
                      type="submit"
                      // className={submitButton ? "disabled" : ""}
                      // disabled={submitButton ? "disabled" : ""}
                    />
                  </div>
                </FormBlock>
              </>
            ) : (
              <div>
                {captchaFailed ? (
                  <>
                    <p>Captcha Verification Failed</p>
                    <button onClick={retry}>retry</button>
                  </>
                ) : (
                  <>
                    {!nextStage ? (
                      <div className="loader">Loading...</div>
                    ) : (
                      <>
                        {failedEmail ? (
                          <>
                            <h1>Email Failed Contact us here </h1>
                            <h2>
                              <a href="mailto:hello@studioconscious.com">
                                hello@studioconscious.com
                              </a>
                            </h2>
                            <h3>
                              Or Retry here{" "}
                              <button onClick={retry}>retry</button>
                            </h3>
                          </>
                        ) : (
                          <Success>
                            <h1>Succesfully submitted</h1>
                            <svg
                              className="checkmark"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 52 52"
                            >
                              <circle
                                className="checkmark__circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                              />
                              <path
                                className="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                              />
                            </svg>
                          </Success>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </Form>
        </Loading>
      </ContactBlock>
      <Footer />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    file(relativePath: { eq: "filtered.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productsCurations: allSanityProducts(
      filter: { archived: { eq: false }, page: { eq: "curations" } }
    ) {
      edges {
        node {
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          curationsVsGoods
          archived
        }
      }
    }
    productsGoods: allSanityProducts(
      filter: { archived: { eq: false }, page: { eq: "goods" } }
    ) {
      edges {
        node {
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          curationsVsGoods
          archived
        }
      }
    }
    productsFurniture: allSanityProducts(
      filter: { archived: { eq: false }, page: { eq: "furniture" } }
    ) {
      edges {
        node {
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          curationsVsGoods
          archived
        }
      }
    }
    allSanityConcepts {
      edges {
        node {
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityColors {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      tertiaryColor {
        hex
      }
    }
    sanityHomePage {
      logoImageColor {
        alt
        asset {
          fixed(width: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
      contactImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      missionBanner
      _rawMissionDesc
      creatorsBanner
      creatorsBlock {
        Title
        text
        creatorImage {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      aboutTitle {
        title {
          title
          myColor {
            hex
          }
        }
        text
        blockItem {
          title
          color {
            hex
          }
          urlPath
          image {
            asset {
              fixed(width: 320) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
      AboutTitle
      shopTitle
      quote
      heroImage {
        asset {
          url
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityCurations {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    sanityGoods {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    sanityConceptsPage {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    sanityFurniture {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
  }
`
